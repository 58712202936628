// import js dependencies

import Swiper, { Autoplay, Navigation, Pagination, EffectFade } from 'swiper';
  // import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, EffectFade, Autoplay]);

jQuery(function($) {


	//swiper
	const eventSlider = document.querySelectorAll('.eventSlider-wrapper');

	if(eventSlider.length > 0){
		var i = 0;

		for( i=0; i< eventSlider.length; i++ ) {

			var first = eventSlider[i].nextElementSibling;
			var second = first.nextElementSibling;
			var third = second.nextElementSibling;

			eventSlider[i].classList.add('eventSlider-wrapper-' + i); //slider
			first.classList.add('swiper-pagination-' + i); //pagination
			second.classList.add('eventSlider-button-next-' + i); //next
			third.classList.add('eventSlider-button-prev-' + i); //previous

			var slider = new Swiper('.eventSlider-wrapper-' + i, {
				speed: 1000,
				spaceBetween: 50,
				direction: 'horizontal',
				loop: true,
				autoplay: false,
				
				navigation: {
					nextEl: '.eventSlider-button-next-' + i,
					prevEl: '.eventSlider-button-prev-' + i,
				},
				breakpoints: {
					// when window width is >= ''
					991: {
						slidesPerView: 4,
					},
					768: {
						slidesPerView: 3,
					},
					576: {
						slidesPerView: 2,
					},
					1: {
						slidesPerView: 1,
					},
				},
			});

		}
	}


	//menu
	$('body').on('click', '.burger', function(event){
		$('#menuPage').toggleClass('mobileOpen');
		$('#page').toggleClass('mobileOpen');

		$(this).toggleClass('active');

		$('.menu .content').toggleClass('open');
		$('.menuContainer').toggleClass('active');
		$('.menuContainer .menu').toggleClass('show');
	});



	//close menu for desktop if resize is bigger than breakpoint
	$(window).on('resize', function(){
		var win = $(this); //this = window

		if (win.width() > 991) {

			$('#page').removeClass('mobileOpen');
			$('.menu .content').removeClass('open');
			$('.menuContainer').removeClass('active');
			$('.menuContainer .menu').removeClass('show');

			$('.mobileMenu').removeClass('open');
			$('.burger').removeClass('active');
			
		}
    })
	
});